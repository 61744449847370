import React, { RefObject, useEffect, useState, VFC } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { Button } from 'semantic-ui-react'
import { Settings } from '../../config'
import { emailRegex, PAYPAL_URL } from '../../const'
import { Coupon } from '../../utils/coupon'
import Mutation from '../../shared/mutation'
import Notifications from '../../shared/notifications'
import { AppleBtn, GoogleBtn, PaypalBtn } from '../../shared/paypal-btn'
import Router from '../../shared/router'
import Session from '../../shared/storages/session'
import { CardToken, PaypalToken, Subscribe } from '../../type'
import { PaymentData } from './ICardProps'
import Cookies from 'universal-cookie'
import {
  mutationPaypalMutation,
  mutationSubscribeMutation,
} from '../../graphql/checkout'
import { useStripe, useElements, ExpressCheckoutElement } from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js'

const cookies = new Cookies()

const ZIP_ERROR_MATCHER = '27'
const ZIP_ERROR_MESSAGE =
  'Error: The ZIP code does not match that on file with your bank. Please contact your bank to verify your zip code.'

type Props = {
  type?: 'CARD' | 'PAYPAL' | 'GOOGLE' | 'APPLE';
  data: PaymentData;
  priceId: string;
  cardRef: RefObject<any>;
  captchaRef: RefObject<any>;
  coupon?: Coupon;
  disabled?: boolean;
  activateNow: boolean;
  promotion: boolean;
  paypalReturnPath: string;
  purchaseFinished?: () => void;
  trialEnabled?: boolean;
  checkoutSubscriptionId: string;
  priceIds: string[];
  price?: number;
  subscriptionId?: string;
  checkoutConfigId?: string;
  clientSecret?: string;
};

const Submit: VFC<Props> = ({
  type = 'CARD',
  data: paymentData,
  priceId,
  cardRef,
  captchaRef,
  coupon,
  disabled,
  activateNow,
  promotion,
  paypalReturnPath,
  purchaseFinished,
  checkoutSubscriptionId,
  priceIds,
  price = 0,
  subscriptionId = '',
  checkoutConfigId = '',
  clientSecret = '',
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const subscribeMutation = new Mutation<Subscribe>(mutationSubscribeMutation)

  const paypalMutation = new Mutation<PaypalToken>(mutationPaypalMutation)

  const [loading, setLoading] = useState<boolean>(false)
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()

  useEffect(() => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    if (stripe && price) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Your Total',
          amount: price * 100,
        },
      })

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr)
        }
      })

      pr.on('paymentmethod', async event => {
        // Confirm the PaymentIntent with the payment method returned from the payment request.
        const { error } = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            // eslint-disable-next-line camelcase
            return_url: 'https://example.com/order/123/complete',
          },
        })

        if (error) {
          console.error(error)
          event.complete('fail')
        } else {
          event.complete('success')
          // Proceed with your application's flow, e.g., order processing & confirmation
        }
      })
    }
  }, [stripe])

  const submit = (input: Hash) => {
    if (loading) {
      return
    }
    subscribeMutation.exec({ input:{...input, checkoutSubscriptionId, priceIds} }).then(
      action(() => {
        const result = subscribeMutation.data?.result
        if (result) {
          Notifications.success('Account created successfully')
          Session.authenticated = true
          Session.accessToken = result.token
          purchaseFinished && purchaseFinished()

          const params: string[] = [
            `subscriptionId=${subscriptionId}`,
            `checkoutConfigId=${checkoutConfigId}`,
            `checkoutSubscriptionId=${result.subscriptionId}`
          ]
          if (Router.qs.paypal) {
            const code = (Router.qs.paypal as string).split('|')[1] as string
            if (code) {
              params.push(`coupon=${code}`)
            }
          } else if (Router.qs.coupon) {
            params.push(`coupon=${Router.qs.coupon as string}`)
          } else if (coupon) {
            params.push(`coupon=${coupon.id}`)
          }

          if (activateNow) {
            params.push(`activateNow=${activateNow}`)
          }
          localStorage.removeItem('vs_checkout_cache')
          Router.redirect(
            `/welcome-v2${params.length > 0 ? `?${params.join('&')}` : ''}`
          )
        } else {
          setLoading(false)
          const error = subscribeMutation.error()
          if (error.includes(ZIP_ERROR_MATCHER)) {
            Notifications.error(ZIP_ERROR_MESSAGE, { timing: 8000 })
          } else {
            Notifications.error(error, { timing: 8000 })
          }
        }
      })
    )
  }

  const submitCard = (td: CardToken) => {
    submit({
      type,
      activateNow,
      email: paymentData.email,
      firstName: paymentData.firstName,
      lastName: paymentData.lastName,
      zip: paymentData.zip,
      couponCode: coupon?.id || '',
      affiliateId: cookies.get('affiliateId') || '',
      clickId: cookies.get('clickId') || '',
      pubId: cookies.get('pubId') || '',
      promotion: promotion,
      signupSource: location.pathname.split('/')[1],
      card: { token: td.token },
    })
  }

  const submitPayPal = (token: string, couponCode?: string) => {
    submit({
      type,
      activateNow,
      zip: '0000',
      email: paymentData.email,
      couponCode: coupon?.id || couponCode || '',
      affiliateId: cookies.get('affiliateId') || '',
      clickId: cookies.get('clickId') || '',
      pubId: cookies.get('pubId') || '',
      promotion: promotion,
      signupSource: location.pathname.split('/')[1],
      paypal: { token },
    })
  }

  const handleAuthorizeNet = () => {
    const authData = {}
    // @ts-ignore
    authData.clientKey = Settings.authorizeNetPublicKey
    // @ts-ignore
    authData.apiLoginID = Settings.authorizeNetLoginId

    const cardData = {}
    // @ts-ignore
    cardData.cardNumber = paymentData.card.number.replace(/\s/g, '')
    // @ts-ignore
    cardData.month = paymentData.card.expiry.split('/')[0].replace(/\s/g, '')
    // @ts-ignore
    cardData.year = paymentData.card.expiry.split('/')[1].replace(/\s/g, '')
    // @ts-ignore
    cardData.cardCode = paymentData.card.cvv.replace(/\s/g, '')
    // @ts-ignore
    cardData.zip = paymentData.zip.replace(/\s/g, '')
    // @ts-ignore
    cardData.fullName =
      `${paymentData.firstName} ${paymentData.lastName}`.trim()

    const secureData = {}
    // @ts-ignore
    secureData.authData = authData
    // @ts-ignore
    secureData.cardData = cardData

    // @ts-ignore
    Accept.dispatchData(secureData, response => {
      if (response.messages.resultCode === 'Error') {
        for (let i = 0; i < response.messages.message.length; i++) {
          const msg = response.messages.message[i].text
          Notifications.error(msg, { timing: 8000 })
        }
        setLoading(false)
      } else {
        const token = response.opaqueData.dataValue
        try {
          submitCard({ token })
        } catch (e) {
          Notifications.error(e.message || 'The card information is invalid')
          setLoading(false)
        }
      }
    })
  }

  const handleChargebeeJs = () => {
    const cardTokenizer = cardRef.current
    cardTokenizer
      .tokenize()
      .then((cardToken: CardToken) => {
        submitCard(cardToken)
      })
      .catch(() => {
        Notifications.error('The card information is invalid')
        setLoading(false)
      })
  }

  const handleSubmit = () => {
    if (loading) {
      return
    }

    const captchaTokenizer = captchaRef.current
    captchaTokenizer.executeAsync().then((token: string) => {
      captchaTokenizer.reset()
      if (token && type === 'CARD') {
        setLoading(true)

        if (
          paymentData.card &&
          paymentData.card.number &&
          paymentData.card.number.length > 0
        ) {
          handleAuthorizeNet()
        } else {
          handleChargebeeJs()
        }
      }
    })
  }

  const handlePayPal = () => {
    setLoading(true)
    const payload = {
      id: priceId,
      activateNow,
      email: paymentData.email,
      coupon: coupon?.name || '',
      returnPath: paypalReturnPath,
    }

    paypalMutation.exec(payload).then(() => {
      const token = paypalMutation.data?.token
      if (token) {
        window.location.href = `${PAYPAL_URL}${token}`
      } else {
        setLoading(false)
        Notifications.error(paypalMutation.error(), { timing: 8000 })
      }
    })
  }

  const handleCreateStripePayment = async () => {
    setLoading(true)
    if (paymentRequest) {
      paymentRequest.show() // Show the native payment interface
    }
    setLoading(false)
  }

  const handleStripeCheckoutClick = ({resolve}:{resolve:any}) => {
    const options = {
      emailRequired: true
    }
    resolve(options)
  }

  const handleStripeCheckoutConfirm = async() => {
    if(stripe && elements){
      const {error} = await stripe.confirmPayment({
      // `Elements` instance that's used to create the Express Checkout Element.
        elements,
        // `clientSecret` from the created PaymentIntent
        clientSecret,
        confirmParams: {
          // eslint-disable-next-line camelcase
          return_url: 'https://example.com/order/123/complete',
        },
      // Uncomment below if you only want redirect for redirect-based payments.
      // redirect: 'if_required',
      })

      if (error) {
      // This point is reached only if there's an immediate error when confirming the payment. Show the error to your customer (for example, payment details incomplete).
      } else {
      // Your customer will be redirected to your `return_url`.
      }
    }
  }

  useEffect(() => {
    if (!Router.qs.paypal || (!coupon && !!Router.qs.coupon)) {
      return
    }

    if (loading) {
      return
    }

    const result = (Router.qs.paypal as string).split('|')[3] as string
    const couponCode = (Router.qs.paypal as string).split('|')[1] as string
    if (result === 'completed') {
      setLoading(true)
      submitPayPal(Router.qs.token as string, couponCode)
    } else {
      Notifications.warning('The operation on PayPal was canceled.')
    }
  }, [coupon])

  const isCard = type === 'CARD'

  const payMethodReady =
    paymentData.email !== undefined && emailRegex.test(paymentData.email)

  const cardReady =
    payMethodReady &&
    paymentData.zip !== undefined &&
    paymentData.zip !== '' &&
    paymentData.firstName !== undefined &&
    paymentData.firstName !== '' &&
    paymentData.lastName !== undefined &&
    paymentData.lastName !== ''

  return (
    <>
      {isCard && (
        <Button
          disabled={!cardReady || disabled}
          onClick={handleSubmit}
          content={activateNow ? 'Continue' : 'Start Free Trial'}
          color="red"
          size="huge"
          primary
          fluid
          loading={loading}
        />
      )}
      {type === 'PAYPAL' && (
        <PaypalBtn
          disabled={!payMethodReady || disabled}
          onClick={handlePayPal}
          loading={loading}
        />
      )}
      {type === 'GOOGLE' && (
        <ExpressCheckoutElement
          onClick={handleStripeCheckoutClick}
          onConfirm={handleStripeCheckoutConfirm}
        />
      )}
      {type === 'APPLE' && (
        <AppleBtn
          disabled={disabled}
          onClick={handleCreateStripePayment}
          loading={loading}
        />
      )}
    </>
  )
}

const oSubmit = observer(Submit)
export { oSubmit as Submit }
