import React from 'react'
import { Button, Image } from 'semantic-ui-react'

interface AddonCardProps {
  title: string;
  description: string;
  price: string;
  icon: string;
  handleClickOnAdd?: (event: React.MouseEvent<HTMLElement>) => void;
  isSelected: boolean;
}

const AddonCard: React.FC<AddonCardProps> = ({
  title,
  description,
  icon,
  price,
  handleClickOnAdd,
  isSelected,
}) => (
  <div className={`addon-card${isSelected?' addon-card-selected':''}`}>
    <div className="detail">
      <div className="description">
        <div className="title">{title}</div>
        <div className="subTitle">{description}</div>
        <div className="price">
          for <span>{price}$</span>/month
        </div>
      </div>
      <div className="logo">
        <Image as="img" src={icon} />
      </div>
    </div>
    <Button
      className={`addon-button${isSelected ? '-selected' : ''}`}
      onClick={handleClickOnAdd}
    >
      {isSelected ? 'Added' : 'Add'}
    </Button>
  </div>
)

export default AddonCard
