import React, {VFC} from 'react'
import {Button} from 'semantic-ui-react'
import {PeriodUnit, Plan} from '../../type'
import {toCouponPrice, toMoney} from '../../shared/format'
import {gql, useQuery} from '@apollo/client'
import {Coupon} from '../../utils/coupon'

const styles = require('./upsell.module.scss')
const tickGreen = require('../../assets/images/tick-green-round.svg')

type UpsellProps = {
  currentPlan: Plan
  onUpgrade: (newPlan: Plan) => void
  coupon?: Coupon
}

/**
 * Upsell component to render an upsell offer for a user to upgrade to a new plan.
 *
 * @param currentPlan
 * @param onUpgrade
 * @constructor
 */
const Upsell: VFC<UpsellProps> = ({currentPlan, onUpgrade, coupon}) => {
  /**
   * If current plan is already family then just bail out
   */
  const isFamily = currentPlan.item?.metadata?.is_family || false

  /// Fetch all plans to find a suggested one
  const { data } = useQuery<{ plans: Plan[] }>(gql`
    {
      plans {
        id
        name
        externalName
        description
        price
        period
        periodUnit
        pricingModel
        currencyCode
        trialPeriod
        trialPeriodUnit
        billingCycles
        freeQuantity
        itemFamily {
          name
        }
        item {
            name
            metadata
        }
        __typename
      }
    }
  `)

  /// If we don't have any plans then bail out
  if (!data || data.plans.length === 0) {
    return null
  }

  /**
   * Find a plan
   * @param product
   * @param family
   * @param period
   * @param periodUnit
   */
  const findPlan = (product: string, family: boolean, period: number, periodUnit: PeriodUnit): Plan | undefined => data.plans
    .filter((value: Plan) => value.item?.metadata !== null)
    .filter((value: Plan) => typeof (value.item?.metadata) === 'object')
    .filter((value: Plan) => value.item!.metadata!.is_family === family)
    .filter((value: Plan) => value.item!.metadata!.product === product)
    .find(plan => plan.period === period && plan.periodUnit === periodUnit)

  /**
   * Calculate suggested plan
   */
  const findSuggestedPlan = (): Plan | undefined => {
    const currentProduct = currentPlan.item!.metadata!.product
    let suggestedProduct = currentProduct
    let family = isFamily
    if (currentProduct === 'vpn') {
      suggestedProduct = 'one'
    }
    if (currentProduct === 'one') {
      if (!isFamily) {
        family = true
      }
    }

    return findPlan(suggestedProduct, family, currentPlan.period, currentPlan.periodUnit)
  }

  /// Find a family plan with the same period and period unit as the current plan
  const suggestedPlan = findSuggestedPlan()

  /// If we don't have a suggested plan then bail out
  if (!suggestedPlan) {
    return null
  }

  /// Calculate the difference in price between the current plan and the suggested plan
  const difference = toCouponPrice(parseFloat(suggestedPlan.price), coupon) - toCouponPrice(parseFloat(currentPlan.price), coupon)

  // If the difference is less than 0 then bail out
  if (difference <= 0) {
    return null
  }

  // Calculates the extra monthly price
  const monthlyPrice = suggestedPlan.periodUnit === 'MONTH' ? difference / suggestedPlan.period :
    difference / (suggestedPlan.period*12)

  return (<div className={styles.Container}>
    <span className={styles.Headline}>Would you like to upgrade to {suggestedPlan.item?.name || ''} for only {toMoney(monthlyPrice)}/mo more?</span>
    <span className={styles.Text}><img src={tickGreen} className={styles.Tick} /> 5 Users</span>
    <Button size={'large'} color={'red'} onClick={() => onUpgrade(suggestedPlan)} className={styles.Button} primary>Upgrade</Button>
  </div>)
}


export { Upsell }
