import React from 'react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleText } from '../../SimpleText'
const ArrowUp = require('~assets/images/icons/icon-arrow-up.svg')
const ArrowDown = require('~assets/images/icons/icon-arrow-down.svg')

interface PaymentMethodProps {
  title: string;
  isCardOpen: boolean;
  icon: string;
  iconAlt: string;
  handleToggleCard: () => void;
  children: React.ReactNode;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  title,
  isCardOpen,
  icon,
  iconAlt,
  handleToggleCard,
  children,
}) => (
  <SimpleBox
    mt={3}
    borderRadius="8px"
    backgroundColor="white"
    padding="20px"
    cursor="pointer"
  >
    <SimpleBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      onClick={handleToggleCard}
    >
      <SimpleBox display="flex" alignItems="center">
        <SimpleText size="title2">{title}</SimpleText>
        <img height={23} src={icon} alt={iconAlt} className="payment-icon" />
      </SimpleBox>
      <div onClick={handleToggleCard} className="toggleButton">
        <img src={isCardOpen ? ArrowUp : ArrowDown} />
      </div>
    </SimpleBox>
    {isCardOpen && children}
  </SimpleBox>
)

export default PaymentMethod
