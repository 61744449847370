import { action, observer } from '@decorators'
import { useToggle } from '@shopify/react-hooks'
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useMemo, useState, VFC } from 'react'
import {
  Form,
  FormField,
  Header,
  Image,
  Loader,
  Container,
} from 'semantic-ui-react'
import { useQuery } from '@apollo/client'
import Mutation from '~shared/mutation'
import Notifications from '~shared/notifications'
import Router from '~shared/router'
import Session from '~shared/storages/session'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleCard } from '../components/SimpleCard'
import { SimpleLayout } from '../components/SimpleLayout'
import { SimplePasswordInput } from '../components/SimplePasswordInput/SimplePasswordInput'
import { useNavigation } from '../hooks/useNavigation'
import { Settings } from '../config'
import { Item } from '../shared/types'
import {
  queryCheckoutUpsellItems,
  queryCheckoutUpsells,
} from '../graphql/checkout'
import Upsells from '../components/Checkout/Upsells'

const PROMOTION_ITEM = Settings.welcomePromotionItem
const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')

type ItemPrice = {
  itemId: string;
  itemType: string;
  period: number;
  periodUnit: string;
  item: Item;
};
type Account = {
  subscriptions: { id: string; itemPrices: ItemPrice[]; items: Item[] }[];
};
export type Addon = {
  id: string;
  period: number;
  periodUnit: string;
  price: number;
};
export type Addons = { addons: Addon[]; account: Account };

type Result = { createPassword: boolean };

const Welcome: VFC = () => {
  const { setNav, resetNav, setSide, resetSide } = useNavigation()

  const [password, setPassword] = useState('')
  const [strength, setStrength] = useState(0)
  const [withAddon, setWithAddon] = useState<'loading' | 'visible' | 'hidden'>(
    PROMOTION_ITEM ? 'loading' : 'hidden'
  )
  const [isUpsellItemsLoaded, setIsUpsellItemsLoaded] = useState(false)
  const [isUpsellsLoaded, setIsUpsellsLoaded] = useState(false)

  const loading = useToggle(false)

  const mutation = new Mutation<Result>(
    'mutation($password: String!) { createPassword(password: $password) }'
  )
  const { data: upsells, loading: loadingUpsells } = useQuery(
    queryCheckoutUpsells,
    {
      variables: { checkoutSubscriptionId: Router.qs.subscriptionId },
    }
  )

  const { data: upsellItems, loading: loadingUpsellItems } = useQuery(
    queryCheckoutUpsellItems,
    { variables: { id: Router.qs.checkoutConfigId } }
  )


  const upsellItemsToShow = useMemo(
    () => upsellItems?.checkoutConfiguration?.cartExtraFeatures.filter(
      (item: any) => upsells?.useCheckoutConfigurationUpsell?.availableUpsells.includes(
        item.chargebeeId
      )
    ) || [],
    [upsellItems, upsells]
  )

  useEffect(() => {
    if (
      isUpsellsLoaded &&
      isUpsellItemsLoaded &&
      (!upsellItemsToShow || upsellItemsToShow?.length < 1)
    ) {
      setWithAddon('hidden')
      return
    }
    if (
      upsells?.useCheckoutConfigurationUpsell &&
      upsellItems?.checkoutConfiguration &&
      withAddon === 'loading'
    ) {
      setWithAddon('visible')
    }
  }, [
    isUpsellsLoaded,
    isUpsellItemsLoaded,
    withAddon,
    upsells?.useCheckoutConfigurationUpsell,
    upsellItems?.checkoutConfiguration,
  ])

  useEffect(() => {
    if (loadingUpsells === false) {
      setIsUpsellsLoaded(true)
    }
  }, [loadingUpsells])

  useEffect(() => {
    if (loadingUpsellItems === false) {
      setIsUpsellItemsLoaded(true)
    }
  }, [loadingUpsellItems])

  const handleSubmit = () => {
    loading.setTrue()
    mutation.exec({ password }).then(
      action(() => {
        loading.setFalse()
        if (mutation.data?.createPassword) {
          Session.update({ hasPassword: true })
          Router.redirect('/apps')
        } else {
          Notifications.error(mutation.error())
        }
      })
    )
  }

  const handlePasswordChange = (v: string, s: number) => {
    setPassword(v)
    setStrength(s)
  }

  useEffect(() => {
    setNav('hidden')
    setSide('hidden')
    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  const handleCloseUpsells = () => {
    setWithAddon('hidden')
  }

  if (withAddon === 'loading' || !isUpsellItemsLoaded || !isUpsellsLoaded) {
    return (
      <SimpleLayout>
        <Container>
          <Loader active />
        </Container>
      </SimpleLayout>
    )
  } else if (withAddon === 'visible') {
    return (
      <Upsells
        trialEnabled={true}
        activateNow={true}
        upsells={upsellItemsToShow}
        handleCloseUpsells={handleCloseUpsells}
        subscriptionId={`${
          upsells?.useCheckoutConfigurationUpsell?.subscriptionId ?? ''
        }`}
      />
    )
  }

  return (
    <SimpleLayout>
      <SimpleBox display="grid" alignItems="center" width="100%">
        <SimpleBox
          width={590}
          mx="auto"
          className="form set-password-form"
          padding={0}
        >
          <SimpleBox textAlign="center">
            <Image
              src={PlanCheckMarkIcon}
              height={58}
              width={58}
              style={{ margin: '0 auto' }}
            />
            <SimpleBox my={4}>
              <Header as="h4">Payment successful. Thank you!</Header>
            </SimpleBox>
            <SimpleBox mb={8}>
              <div className="text text--subdued text--large">
                Set your password to sign in and get started with your trial.
                <br />
                <span className="text">For {Session.profile?.email}</span>
              </div>
            </SimpleBox>
          </SimpleBox>
          <SimpleCard rounded="heavy" padding="heavy" fluid>
            <SimpleBox>
              <Form id="reset-password" onSubmit={handleSubmit}>
                <SimpleBox mt={2} mb={6}>
                  <FormField>
                    <label htmlFor="password">Password</label>
                    <SimplePasswordInput
                      size="large"
                      value={password}
                      onChange={handlePasswordChange!}
                      fluid
                    />
                  </FormField>
                  <SimpleBox mt={2}>
                    <div>
                      A strong password has more than 8 character and includes
                      combination of upper and lower cases nad symbols.
                    </div>
                  </SimpleBox>
                </SimpleBox>
                <SimpleBox maxWidth={276} mx="auto">
                  <Form.Button
                    color="red"
                    primary
                    disabled={strength < 2}
                    size="huge"
                    content="Get started"
                    fluid
                    loading={loading.value}
                  />
                </SimpleBox>
              </Form>
            </SimpleBox>
          </SimpleCard>
        </SimpleBox>
      </SimpleBox>
    </SimpleLayout>
  )
}

export default observer(Welcome)
