import { useQuery } from '@apollo/client'
import {
  queryCheckoutConfig,
  queryCheckoutConfigDetail,
} from '../graphql/checkout'

export interface ICartExtraFeatures {
  id: string;
  title: string;
  description: string;
  price: number;
  imageUrl: string;
  chargebeeId: string;
  position: number;

}

interface GetCheckoutConfigResult {
  data: {
    checkoutSubscription: {
      id: string;
    };
    checkoutConfiguration: {
      id: string;
      title: string;
      template: string;
      freeMonths: number;
      defaultCoupon: string;
      payNowEnabled: boolean;
      checkoutSteps: Array<{
        id: string;
        stepType: string;
        position: number;
      }>;
      cartSteps: Array<{
        id: string;
        stepType: string;
        position: number;
      }>;
      cartExtraFeatures: Array<ICartExtraFeatures>;
      cartPaymentMethods: Array<{
        id: string;
        paymentMethod: string;
        position: number;
      }>;
    };
  };
  loading: boolean;
}

export const useGetCheckoutConfig = (): GetCheckoutConfigResult => {
  const { data: checkoutConfig, loading: loadingCheckoutConfig } =
    useQuery(queryCheckoutConfig)

  const { data: checkoutConfigDetail, loading: loadingCheckoutConfigDetail } =
    useQuery(queryCheckoutConfigDetail, {
      variables: {
        id: checkoutConfig?.useCheckoutConfiguration.checkoutConfiguration.id,
      },
    })

  return {
    data: {
      checkoutSubscription:
        checkoutConfig?.useCheckoutConfiguration?.checkoutSubscription,
      checkoutConfiguration: checkoutConfigDetail?.checkoutConfiguration,
    },
    loading: loadingCheckoutConfigDetail || loadingCheckoutConfig,
  }
}
