import { classNames, variationName } from '@shopify/css-utilities'
import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { Button, Image, Menu } from 'semantic-ui-react'
import { NavigationDisplay } from '../../contexts/Navigation'
import Session from '../../shared/storages/session'
import { SimpleBox } from '../SimpleBox'

const styles = require('./Navigation.module.scss')
const Logo = require('~assets/images/logo-black.svg')
const LogoSymbol = require('~assets/images/logo-symbol.svg')
const UserIcon = require('~assets/images/icons/profile.svg')

type Props = { type: NavigationDisplay, navClassNames?: string }
const Navigation: FC<Props> = observer(({ type, navClassNames }) => {
  const styleMain = classNames(styles.Main, styles[variationName('type', type)])

  const isSimple = type === 'simple'
  const hiddenSignIn = type === 'no-sign-in'

  return (
    <Menu fixed={'top'} borderless secondary={isSimple} className={`${styleMain} ${navClassNames}`}>
      <Menu.Item header>
        <Image src={Logo} height={30} id="logo" className={styles.logoDesktop} />
        <Image src={LogoSymbol} height={42} id="logo-mobile" className={styles.logoMobile} />
      </Menu.Item>
      {!isSimple && (
        <Menu.Menu position="right">
          {!Session.authenticated && !hiddenSignIn && (
            <Menu.Item>
              <Button size="large" as="a" href="/">
                <SimpleBox display="flex" gap={12}>
                  <Image src={UserIcon} height={28} style={{ marginLeft: -6 }} />
                  Sign In
                </SimpleBox>
              </Button>
            </Menu.Item>
          )}
        </Menu.Menu>
      )}
    </Menu>
  )
})

export { Navigation }
