import React, { FC, useMemo, useState } from 'react'
import { SimpleBox } from '../../SimpleBox'
import { Button, Image } from 'semantic-ui-react'
import { SimpleText } from '../../SimpleText'
import Tags from '../Tags'
import { useMutation } from '@apollo/client'
import { attachAddon } from '../../../graphql/checkout'
import Notifications from '../../../shared/notifications'

const WarningIcon = require('~assets/images/icons/warning.svg')
const Logo = require('~assets/images/logo-black.svg')
const LogoSymbol = require('~assets/images/logo-symbol.svg')
const TempImage = require('./temp-image.png')
const styles = require('./Upsells.module.scss')

interface Props {
  trialEnabled: boolean;
  activateNow: boolean;
  upsells: any[];
  handleCloseUpsells: () => void;
  subscriptionId: string;
}

const Upsells: FC<Props> = ({
  trialEnabled,
  activateNow,
  handleCloseUpsells,
  upsells,
  subscriptionId,
}) => {
  const [countdown, setCountdown] = useState(5 * 60)

  const [
    attachAddonMutation,
    { loading: attachAddonMutationLoading, error: attachAddonMutationError },
  ] = useMutation(attachAddon)

  const handleSkip = () => {
    handleCloseUpsells()
  }
  const handleNext = async () => {
    const args = {
      subscription: subscriptionId,
      id: upsells[0].chargebeeId,
      coupon: '',
    }
    try {
      await attachAddonMutation({ variables: args })
      Notifications.success('Addon attached successfully')
    } catch (error) {
      Notifications.error(attachAddonMutationError?.message || 'Unable to attach addon')
    }
    handleCloseUpsells()
  }
  setTimeout(() => setCountdown(countdown - 1), 1000)

  const countdownText = useMemo(
    () => `${Math.floor(countdown / 60)}:${
      countdown % 60 === 0
        ? '00'
        : countdown % 60 < 10
          ? `0${countdown % 60}`
          : countdown % 60
    }`,
    [countdown]
  )

  return (
    <SimpleBox width="100%" height="100%" display="flex" flexDirection="column">
      <SimpleBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Image src={Logo} height={30} id="logo" className="show-on-web" />
            <Image
              src={LogoSymbol}
              height={42}
              id="logo-mobile"
              className="show-on-mobile"
            />
            <div className={styles.checkout}>
              <p>|</p>
              <SimpleText size="title2" color="grey">
                Checkout
              </SimpleText>
            </div>
          </div>
          <Tags trialEnabled={trialEnabled} activateNow={activateNow} />
        </div>
        <div className={styles.container}>
          <SimpleBox textAlign="center">
            <div>
              <SimpleText size="large">Wait! Before you continue…</SimpleText>
            </div>
            <SimpleText size="large">
              Today we have a special offer for you!
            </SimpleText>
          </SimpleBox>
          <SimpleBox
            marginTop={52}
            background="rgba(26, 178, 51, 0.15)"
            borderRadius={10}
          >
            <SimpleBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              padding="6.5px"
            >
              <SimpleBox
                alignItems="center"
                justifyContent="center"
                padding="0px 10px"
                borderRadius={8}
                background="#1AB233"
                display="flex"
                maxWidth={142}
              >
                <SimpleText size="buttonText" weight="bold" color="white">
                  ONE TIME - 50% OFF
                </SimpleText>
              </SimpleBox>
            </SimpleBox>
            <SimpleBox
              background="#FFFFFF"
              borderRadius={8}
              padding={24}
              flexDirection="column"
              alignItems="center"
            >
              <div className={styles.offerBox}>
                <div className={styles.offerDetailBox}>
                  <div className={styles.offerDetail}>
                    <span>
                      <span className={styles.textGreen}>50%</span> for
                    </span>
                    <span>{upsells[0]?.title}</span>
                  </div>
                  <span className={styles.priceText}>
                    <span className={styles.originalPrice}>
                      ${upsells[0]?.price}
                    </span>{' '}
                    ${(upsells[0]?.price / 2).toFixed(2)}
                  </span>
                </div>
                <Image
                  src={upsells[0]?.imageUrl || TempImage}
                  className={styles.offerImage}
                />
              </div>
              {/* <SimpleBox marginTop={22}>
                <div className={styles.bulletPointsTitle}>
                  What you&lsquo;ll get:
                </div>
                <Grid columns={2} stackable>
                  {[
                    'Guard Against Identity Theft',
                    'Say No to Robocalls',
                    'Avoid Spam & Cyber Threats',
                    'Break the Data Broker Loop',
                    'Protection from Prying Eyes',
                    'No More Unpleasant Suprises',
                  ].map((item, index) => (
                    <GridColumn key={index} className={styles.gridCol}>
                      <div className={styles.gridItem}>
                        <Image src={PlanCheckMarkIcon} height={16} width={16} />
                        <SimpleText size="text5">{item}</SimpleText>
                      </div>
                    </GridColumn>
                  ))}
                </Grid>
              </SimpleBox> */}
              <div className={styles.actionsRow}>
                <Button
                  color="grey"
                  className={styles.skipButton}
                  onClick={handleSkip}
                  loading={attachAddonMutationLoading}
                >
                  Skip
                </Button>
                <Button
                  color="red"
                  className={styles.button}
                  onClick={handleNext}
                  loading={attachAddonMutationLoading}
                >
                  Yes, order with one-click!
                </Button>
              </div>
            </SimpleBox>
          </SimpleBox>
        </div>
      </SimpleBox>
      <div className={styles.expiredRow}>
        <div className={styles.expiredBody}>
          <Image src={WarningIcon} width={24} height={24} />
          <span>
            Offer expires in <b>{countdownText}</b>
          </span>
        </div>
      </div>
    </SimpleBox>
  )
}

export default Upsells
