/* eslint-disable complexity */
import { useMutation, useQuery } from '@apollo/client'
import { set } from 'dot-prop'
// eslint-disable-next-line no-use-before-define
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  Form,
  FormField,
  Grid,
  GridRow,
  Label,
  Modal,
} from 'semantic-ui-react'
import { SimpleBox } from '../../components/SimpleBox'
import { SimpleCard } from '../../components/SimpleCard'
import { SimpleForm } from '../../components/SimpleForm'
import { SimpleText } from '../../components/SimpleText'
import { observer } from '../../decorators'
import { Submit } from '../../fragments/payment/SubmitNew'
import { toMoney } from '../../shared/format'
import Router from '../../shared/router'
import { ChargebeeItemPrice } from '../../shared/types'
import { Plan } from '../../type'
import { useNavigation } from '../../hooks/useNavigation'
import { DiscountWarning } from '../../fragments/modal/payment/DiscountWarning'
import { Coupon, CouponData } from '../../utils/coupon'
import { ActivateNow } from '../../fragments/ActivateNow'
import { PaymentData } from '../../fragments/payment/ICardProps'
import { CardComponent } from '../../fragments/payment/CardComponent'
import { useSiteSetting } from '../../hooks/useSiteSetting'
import {
  trackBeginCheckout,
  trackEmailValidationSuccess,
  trackPurchase,
} from '../../utils/tracking'
import { emailRegex } from '../../const'
import PaymentMethod from '../../components/Checkout/PaymentMethod'
import AddonCard from '../../components/Checkout/AddonCard'
import SummaryCard from '../../components/Checkout/SummaryCard'
import Tags from '../../components/Checkout/Tags'
import {
  IExtraFeature,
  applyCoupon,
  createPaymentIntent,
  queryPlan,
} from '../../graphql/checkout'
import { parseStringToCamelCase } from '../../utils'
import { useGetCheckoutConfig } from '../../hooks/useGetCheckoutConfig'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe('pk_test_YhfQBm8rSlOFGB1UcTpysnp8')

const PaymentPayPalIcon = require('~assets/images/icons/payment-paypal.svg')
const PaymentCardsImage = require('~assets/images/payment-cards.svg')
const PaymentGoogleImage = require('~assets/images/payment-google.svg')
const PaymentAppleImage = require('~assets/images/payment-apple.svg')
type PriceProperties = 'id' | 'name' | 'externalName' | 'price' | 'trialPeriod';
export type Price = Pick<ChargebeeItemPrice, PriceProperties>;
type Result = { plan: Plan };

export type CardDetails = {
  number: string;
  expiry: string;
  cvv: string;
  token?: string;
};
type CouponWarningProps = {
  coupon: Coupon;
  plan: Plan;
};

const CheckoutProduct: FC & { authorize: boolean; auth: string } = (props: {
  children?: React.ReactNode;
  trialEnabled: boolean;
  promotion: boolean;
}) => {
  const forceAuthorizeOne = useSiteSetting('FORCE_AUTHORIZE_ON_ONE_PLANS')
  const cardRef = useRef<any>()
  const captchaRef = useRef<any>()
  const { setNav, resetNav } = useNavigation()
  const price = Router.params.price as string
  const priceIds = price && price.includes(':') ? price.split(':') : [price]
  const [firstPlanId, setFirstPlanId] = useState(priceIds[0])
  const [firstLoading, setFirstLoading] = useState(true)
  const paypalData = ((Router.qs.paypal as string) || '').split('|')
  const [activateNow, setActivateNow] = useState<boolean>(
    paypalData[2] === '1'
  )
  const [coupon, setCoupon] = useState<Coupon | undefined>(undefined)
  const [couponWarningPayload] = useState<CouponWarningProps>()
  const [modal, setModal] = useState<'discount-warning' | undefined>(undefined)
  const [cardErrors, setCardErrors] = useState<string[]>([])
  const [data, setData] = useState<PaymentData>({
    email: ((Router.qs.email as string) || paypalData[0] || '').replace(
      / /g,
      '+'
    ),
    firstName: '',
    lastName: '',
    zip: '',
    card: { number: '', expiry: '', cvv: '' },
    paypal: {},
    captcha: false,
  })
  const [paymentMethod, setPaymentMethod] = useState<
    'CARD' | 'PAYPAL' | 'GOOGLE' | 'APPLE' | undefined
  >(Router.qs.paypal ? 'PAYPAL' : undefined)
  const [addAnotherUser, setAddAnotherUser] = useState(false)
  const [addons, setAddons] = useState<Array<string>>([])

  const [
    createPaymentIntentMutation,
    { loading: createPaymentIntentMutationLoading, data: paymentIntent },
  ] = useMutation(createPaymentIntent)

  const [getCouponData, { data: couponData, loading: loadingCoupon }] =
    useMutation<CouponData>(applyCoupon)
  // createPaymentMutation.exec({
  //   input: {
  //     planIds: [firstPlanId],
  //   },
  // })

  useEffect(() => {
    createPaymentIntentMutation({
      variables: {
        input: {
          planIds: [firstPlanId],
          coupon: coupon?.name,
        },
      },
    })
  }, [])

  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false)
      const checkoutDataCache = JSON.parse(
        localStorage.getItem('vs_checkout_cache') ?? '{}'
      )
      if (Object.keys(checkoutDataCache).length > 0) {
        setData(checkoutDataCache.data)
        setPaymentMethod(checkoutDataCache.type)
        if (checkoutDataCache.coupon) {
          setCoupon(checkoutDataCache.coupon)
        }
        if (checkoutDataCache.activateNow !== undefined) {
          setActivateNow(checkoutDataCache.activateNow)
        }
      }
    }
    setNav('no-sign-in')
    return () => {
      resetNav()
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'vs_checkout_cache',
      JSON.stringify({
        id: firstPlanId,
        coupon,
        activateNow,
        data,
        type: paymentMethod,
      })
    )
  }, [data, coupon, firstPlanId, activateNow, paymentMethod])

  const { data: planData, loading } = useQuery<Result>(queryPlan, {
    variables: { id: firstPlanId },
  })
  const { data: checkoutConfig, loading: loadingCheckoutConfig } =
    useGetCheckoutConfig()

  const cartSteps = checkoutConfig?.checkoutConfiguration?.cartSteps || []

  const trialEnabled =
    checkoutConfig?.checkoutConfiguration?.payNowEnabled &&
    Router.qs.noTrial === undefined

  const handleClosePaymentMethodCard = () => {
    setPaymentMethod(undefined)
  }

  const discount = () => {
    if (!coupon?.discountType) {
      return 0
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return Number(coupon.discountAmount)
    } else {
      return (
        parseFloat(planData?.plan.price || '0') *
        Number(coupon.discountPercentage)
      )
    }
  }

  const handleDataChange = ({ name, value }: { name: string; value: any }) => {
    setData(d => ({ ...set(d, name, value) }))
  }

  const handleEmailChange = (value: string) => {
    const regex = emailRegex
    const isValid = regex && regex.test(value)
    if (isValid) {
      trackEmailValidationSuccess()
    }

    setData(d => ({ ...set(d, 'email', value) }))
  }
  const plan = planData?.plan
  const isFamily = plan?.name.toLowerCase().includes('family')
  const months = (plan?.period || 0) * (plan?.periodUnit === 'YEAR' ? 12 : 1)
  const isOne = plan?.item?.metadata?.product === 'one'

  useEffect(() => {
    setActivateNow(!trialEnabled)
  }, [trialEnabled])

  useEffect(() => {
    if (checkoutConfig?.checkoutConfiguration?.defaultCoupon) {
      getCouponData({
        variables: {
          coupon: checkoutConfig?.checkoutConfiguration?.defaultCoupon,
          planId: firstPlanId,
        },
      })
    }
  }, [checkoutConfig?.checkoutConfiguration?.defaultCoupon, firstPlanId])

  useEffect(() => {
    if (couponData && couponData.coupon) {
      setCoupon(couponData.coupon)
    }
  }, [couponData])

  const handleCouponFound = (coupon: Coupon) => {
    setCoupon(coupon)
    if (plan && coupon) {
      trackBeginCheckout(plan, isFamily, coupon, 'add coupon')
    }
  }

  const handleRemoveCoupon = () => {
    setCoupon(undefined)
    if (plan) {
      trackBeginCheckout(plan, isFamily, undefined, 'remove coupon')
    }
  }

  const linkMarkup = (path: string, text: string) => (
    <a
      href={`https://virtualshield.com/${path}`}
      target="_blank"
      rel="noreferrer"
    >
      {' '}
      {text}
    </a>
  )

  const applyWarnedCoupon = () => {
    if (couponWarningPayload) {
      const path = location.pathname.split('/')[1]
      Router.updateHistory(`/${path}/${couponWarningPayload.plan.id}`)
      setFirstPlanId(couponWarningPayload.plan.id)
      setCoupon(undefined)
      setModal(undefined)
    }
  }

  const handleToggleAddUser = () => {
    setAddAnotherUser(!addAnotherUser)
  }

  const handleSelectAddon = (addonId: string) => {
    if (addons.includes(addonId)) {
      setAddons(addons.filter(id => id !== addonId))
      return
    }
    setAddons(addons.concat(addonId))
  }

  const buttonDisabled = paymentMethod === 'CARD' && cardErrors.length > 0

  if (!plan && !loading) {
    Router.redirect('/pricing')
  }

  useEffect(() => {
    if (plan && !Router.qs.coupon) {
      trackBeginCheckout(plan, isFamily, coupon, 'plan')
    }
  }, [plan])

  useEffect(() => {
    if (coupon && plan && Router.qs.coupon) {
      trackBeginCheckout(plan, isFamily, coupon, 'coupon')
    }
  }, [coupon])

  const cartExtraFeatures = [
    ...(checkoutConfig?.checkoutConfiguration?.cartExtraFeatures || []),
  ]

  const selectedFeatures = cartExtraFeatures.filter(feature => addons.includes(feature.chargebeeId)
  )

  const priceTotal =
    parseFloat(plan?.price || '0') -
    discount() +
    (addAnotherUser ? 29 : 0) +
    addons.reduce((acc, addon) => {
      const feature = cartExtraFeatures.find(f => f.chargebeeId === addon)
      return acc + (feature?.price || 0)
    }, 0)

  if (loadingCheckoutConfig || loading || createPaymentIntentMutationLoading) {
    return <div>loading</div>
  }

  const submitMarkup = () => (
    <>
      {trialEnabled && (
        <ActivateNow
          months={months}
          activateNow={activateNow}
          setActivateNow={setActivateNow}
        />
      )}
      <SimpleBox mt={3} className="submit-box">
        <Submit
          checkoutSubscriptionId={
            checkoutConfig?.checkoutSubscription?.id || ''
          }
          priceIds={[firstPlanId]
            .concat(addons)
            .concat(addAnotherUser ? ['k5i47h4q'] : [])}
          paypalReturnPath={`/${location.pathname.split('/')[1]}`}
          type={paymentMethod}
          data={data}
          priceId={firstPlanId}
          cardRef={cardRef}
          coupon={coupon}
          disabled={buttonDisabled}
          activateNow={activateNow}
          promotion={activateNow && props.promotion}
          captchaRef={captchaRef}
          purchaseFinished={() => plan && trackPurchase(plan, isFamily, activateNow, coupon)
          }
          price={priceTotal}
          subscriptionId={checkoutConfig?.checkoutSubscription?.id}
          checkoutConfigId={checkoutConfig?.checkoutConfiguration?.id}
          clientSecret={paymentIntent?.createPaymentIntent.clientSecret}
        />
      </SimpleBox>
      <SimpleBox mt={3}>
        <div className="text--x-small">
          By clicking Complete Purchase, you agree to our
          {linkMarkup('legal/terms/', 'Terms of Service')} and acknowledge our
          {linkMarkup('legal/privacy/', 'Privacy Policy')}. To ensure
          uninterrupted access, your subscription will automatically renew at
          the end of each billing cycle using the payment method provided until
          you cancel it by disabling auto-renewal, which can be done at any
          time.
        </div>
      </SimpleBox>
    </>
  )

  const steps: any = {
    email: {
      title: (
        <SimpleText size="title3">
          Enter Your Email Address
          <span className="enter-email" />
        </SimpleText>
      ),
      body: (
        <SimpleBox mt={3}>
          <SimpleCard fluid padding="heavy">
            <FormField>
              <label htmlFor="email">Enter email address</label>
              <Grid style={{ margin: 0 }}>
                <GridRow verticalAlign="middle">
                  <SimpleForm.Input
                    type="email"
                    value={data?.email}
                    onChange={(d: any) => handleEmailChange(d.value)}
                    size="large"
                    required
                    fluid
                    showValidation={true}
                  />
                </GridRow>
                <GridRow verticalAlign="middle">
                  <div className="text--x-small">
                    We do not share your information and will contact you only
                    as needed to provide our service.
                  </div>
                </GridRow>
              </Grid>
            </FormField>
          </SimpleCard>
        </SimpleBox>
      ),
    },
    extraFeatures: {
      title: (
        <>
          <SimpleText size="title3">Add extra features</SimpleText>
          <Label style={{ background: '#E8EAF0' }}>
            <SimpleText color="grey" size="textSmall">
              Optional
            </SimpleText>
          </Label>
        </>
      ),
      body: (
        <SimpleBox overflow="hidden" maxWidth="calc(100vw - 48px)">
          <SimpleBox
            mt={3}
            display="flex"
            flexDirection="row"
            className="addon-row"
          >
            {cartExtraFeatures
              ?.sort((a: IExtraFeature, b: IExtraFeature) => a.position > b.position ? 1 : -1
              )
              .slice(0, 3)
              .map((feature: IExtraFeature) => (
                <AddonCard
                  key={feature.id}
                  title={feature.title}
                  description={feature.description}
                  icon={feature.imageUrl}
                  price={feature.price.toString()}
                  handleClickOnAdd={event => {
                    event.preventDefault()
                    handleSelectAddon(feature.chargebeeId)
                  }}
                  isSelected={addons.includes(feature.chargebeeId)}
                />
              ))}
          </SimpleBox>
        </SimpleBox>
      ),
    },
    paymentMethods: {
      title: (
        <SimpleText size="title3">
          Select Your Preferred Payment Method
        </SimpleText>
      ),
      body: {
        creditCard: (
          <PaymentMethod
            title="Credit Card"
            icon={PaymentCardsImage}
            iconAlt="cards"
            isCardOpen={paymentMethod === 'CARD'}
            handleToggleCard={() => {
              if (paymentMethod === 'CARD') {
                handleClosePaymentMethodCard()
                return
              }
              setPaymentMethod('CARD')
            }}
          >
            <SimpleBox
              marginTop={25}
              paddingTop={18}
              borderTop="1px solid #E8EAF0"
            >
              <CardComponent
                data={data}
                forwardRef={cardRef}
                captchaRef={captchaRef}
                onChange={handleDataChange}
                onErrors={setCardErrors}
                forceAuthorize={isOne && forceAuthorizeOne.enabled}
              />
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ),
        paypal: (
          <PaymentMethod
            title="PayPal"
            icon={PaymentPayPalIcon}
            iconAlt="paypal"
            isCardOpen={paymentMethod === 'PAYPAL'}
            handleToggleCard={() => {
              if (paymentMethod === 'PAYPAL') {
                handleClosePaymentMethodCard()
                return
              }
              setPaymentMethod('PAYPAL')
            }}
          >
            <SimpleBox
              marginTop={25}
              paddingTop={18}
              borderTop="1px solid #E8EAF0"
            >
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ),
        googlePay: (
          <PaymentMethod
            title="Google Pay"
            icon={PaymentGoogleImage}
            iconAlt="google"
            isCardOpen={paymentMethod === 'GOOGLE'}
            handleToggleCard={() => {
              if (paymentMethod === 'GOOGLE') {
                handleClosePaymentMethodCard()
                return
              }
              setPaymentMethod('GOOGLE')
            }}
          >
            <SimpleBox
              marginTop={25}
              paddingTop={18}
              borderTop="1px solid #E8EAF0"
            >
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ),
        applePay: (
          <PaymentMethod
            title="Apple Pay"
            icon={PaymentAppleImage}
            iconAlt="apple"
            isCardOpen={paymentMethod === 'APPLE'}
            handleToggleCard={() => {
              if (paymentMethod === 'APPLE') {
                handleClosePaymentMethodCard()
                return
              }
              setPaymentMethod('APPLE')
            }}
          >
            <SimpleBox
              marginTop={25}
              paddingTop={18}
              borderTop="1px solid #E8EAF0"
            >
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ),
      },
    },
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{ mode: 'payment', amount: priceTotal * 100, currency: 'usd' }}
    >
      {/* modal */}
      <Modal
        closeIcon
        size="tiny"
        open={modal !== undefined}
        onClose={() => setModal(undefined)}
      >
        <DiscountWarning
          isOpen={modal === 'discount-warning'}
          payload={couponWarningPayload}
          onFinish={() => {
            applyWarnedCoupon()
          }}
        />
      </Modal>
      <div className="checkout-title">
        <p>|</p>
        <SimpleText size="title2" color="grey">
          Checkout
        </SimpleText>
      </div>
      <Tags trialEnabled={trialEnabled} activateNow={activateNow} />
      <SimpleBox mt={15} p={4} className="checkout">
        <SimpleBox
          maxWidth={1280}
          mx="auto"
          display="flex"
          flexDirection="row"
          gap={21}
        >
          {!loadingCheckoutConfig && cartSteps?.length > 0 && (
            <Form name={'checkout'} style={{ maxWidth: 955 }}>
              {/* account */}
              <SimpleBox
                display="flex"
                alignItems="center"
                gap={12}
                width="100%"
              >
                <SimpleCard
                  color="cornflower"
                  style={{ flex: '0 0 32px' }}
                  base
                  rounded="circle"
                >
                  <SimpleBox
                    height={32}
                    width={32}
                    display="grid"
                    alignContent="center"
                  >
                    <SimpleText size="title2" color="white">
                      1
                    </SimpleText>
                  </SimpleBox>
                </SimpleCard>
                {
                  steps[parseStringToCamelCase(cartSteps[0].stepType) as string]
                    .title
                }
              </SimpleBox>
              {
                steps[parseStringToCamelCase(cartSteps[0].stepType) as string]
                  .body
              }
              <div className="checkout-summary-mobile show-on-mobile">
                <SimpleBox
                  display="flex"
                  alignItems="center"
                  gap={12}
                  width="100%"
                >
                  <SimpleCard
                    color="cornflower"
                    style={{ flex: '0 0 32px' }}
                    base
                    rounded="circle"
                  >
                    <SimpleBox
                      height={32}
                      width={32}
                      display="grid"
                      alignContent="center"
                    >
                      <SimpleText size="title2" color="white">
                        2
                      </SimpleText>
                    </SimpleBox>
                  </SimpleCard>
                  <SimpleText size="title3">Selected plan</SimpleText>
                </SimpleBox>
                <SummaryCard
                  total={toMoney(priceTotal)}
                  coupon={coupon}
                  activateNow={activateNow}
                  plan={plan}
                  userCount={addAnotherUser ? 2 : 1}
                  firstPlanId={firstPlanId}
                  handleToggleAddUser={handleToggleAddUser}
                  handleCouponFound={handleCouponFound}
                  handleRemoveCoupon={handleRemoveCoupon}
                  selectedFeatures={selectedFeatures}
                  bienniallyFreeMonths={
                    checkoutConfig?.checkoutConfiguration?.freeMonths || 3
                  }
                />
              </div>
              {cartSteps.slice(1).map((step: any, index: number) => {
                const currentStepName = parseStringToCamelCase(step.stepType)
                const isCurrentStepPaymentMethods =
                  step.stepType === 'payment_methods'
                return (
                  <React.Fragment key={index}>
                    <SimpleBox
                      mt={5}
                      display="flex"
                      alignItems="center"
                      gap={12}
                      width="100%"
                    >
                      <SimpleCard
                        color="cornflower"
                        style={{ flex: '0 0 32px' }}
                        base
                        rounded="circle"
                      >
                        <SimpleBox
                          height={32}
                          width={32}
                          display="grid"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <div className="show-on-web">
                            <SimpleText size="title2" color="white">
                              {index + 2}
                            </SimpleText>
                          </div>
                          <div className="show-on-mobile">
                            <SimpleText size="title2" color="white">
                              {index + 3}
                            </SimpleText>
                          </div>
                        </SimpleBox>
                      </SimpleCard>
                      {steps[currentStepName].title}
                    </SimpleBox>
                    {isCurrentStepPaymentMethods ? (
                      <>
                        {checkoutConfig?.checkoutConfiguration.cartPaymentMethods.map(
                          (paymentMethod: any) => (
                            <React.Fragment key={paymentMethod.id}>
                              {
                                steps.paymentMethods.body[
                                  parseStringToCamelCase(
                                    paymentMethod.paymentMethod
                                  ) as string
                                ]
                              }
                            </React.Fragment>
                          )
                        )}
                      </>
                    ) : (
                      steps[currentStepName].body
                    )}
                  </React.Fragment>
                )
              })}
            </Form>
          )}
          {plan && (
            <SimpleBox
              display="flex"
              flexDirection="column"
              width={305}
              minWidth={305}
              className="order-summary-desktop"
            >
              <SimpleBox
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                height={32}
                marginBottom={18}
              >
                <SimpleText size="title3">Order summary</SimpleText>
              </SimpleBox>
              <SummaryCard
                total={toMoney(priceTotal)}
                coupon={coupon}
                activateNow={activateNow}
                plan={plan}
                userCount={addAnotherUser ? 2 : 1}
                firstPlanId={firstPlanId}
                handleToggleAddUser={handleToggleAddUser}
                handleCouponFound={handleCouponFound}
                handleRemoveCoupon={handleRemoveCoupon}
                selectedFeatures={selectedFeatures}
                bienniallyFreeMonths={
                  checkoutConfig?.checkoutConfiguration?.freeMonths || 3
                }
              />
            </SimpleBox>
          )}
        </SimpleBox>
      </SimpleBox>
    </Elements>
  )
}

CheckoutProduct.defaultProps = {
  trialEnabled: true,
  promotion: true,
}

CheckoutProduct.authorize = false
CheckoutProduct.auth = '/apps'

export default observer(CheckoutProduct)
