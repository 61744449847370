import React, { FC } from 'react'
import { Label } from 'semantic-ui-react'
import { Plan } from '../../../type'
import { toMoney, toPercent } from '../../../shared/format'
import { Coupon } from '../../../utils/coupon'
import { SimpleBox } from '../../SimpleBox'
import { ICartExtraFeatures } from '../../../hooks/useGetCheckoutConfig'

type Props = {
  total: string;
  coupon?: Coupon;
  activateNow: boolean;
  plan: Plan | undefined;
  userCount: number;
  selectedFeatures: ICartExtraFeatures[];
  bienniallyFreeMonths: number;
};

const Breakdown: FC<Props> = ({
  total,
  coupon,
  plan,
  userCount,
  selectedFeatures = [],
  bienniallyFreeMonths
}) => {
  const period = plan?.period || 12
  const price = parseFloat(plan?.price || '0') + (userCount - 1) * 29
  const couponDiscount = (): string => {
    if (!coupon?.discountType) {
      return toMoney(0)
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return toMoney(coupon.discountAmount || 0)
    } else {
      return toPercent(coupon.discountPercentage || 0, 0)
    }
  }

  const couponValue = (): string => {
    if (!coupon?.discountType) {
      return toMoney(0)
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return toMoney(coupon.discountAmount || 0)
    } else {
      return toMoney(price * (coupon.discountPercentage || 0))
    }
  }

  const planLength =
    period === 24
      ? '2 years'
      : period === 12
        ? '1 year'
        : `${period} month${period > 1 ? 's' : ''}`
  const userText = userCount > 1 ? 'users' : 'user'

  return (
    <div>
      <SimpleBox display="flex" flexDirection="column">
        <SimpleBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {`${planLength}, ${userCount} ${userText}`}
          <div className="text">{toMoney(price)}</div>
        </SimpleBox>
        <SimpleBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {period === 24 && <p>Billed biennially</p>}
          {period === 24 && (
            <SimpleBox
              className="text weight-bold red-text"
              background="rgba(251, 0, 35, 0.10)"
              borderRadius={10}
              padding="4px 10px"
            >
              +{bienniallyFreeMonths} months free
            </SimpleBox>
          )}
        </SimpleBox>
        <SimpleBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          <SimpleBox display="flex" flexDirection="column">
            Discount
            {coupon && (
              <SimpleBox
                display="flex"
                alignItems="center"
                gap={12}
                className="text green-text"
              >
                Coupon added{' '}
                <Label className="pill" color="green" size="tiny">
                  {couponDiscount()}
                </Label>
              </SimpleBox>
            )}
          </SimpleBox>
          <div className="text">
            <div className="text">
              {coupon ? '-' : ''}
              {couponValue()}
            </div>
          </div>
        </SimpleBox>
      </SimpleBox>
      {selectedFeatures.map(feature => (
        <SimpleBox
          key={feature.chargebeeId}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          <p>{feature.title}</p>
          <div className="text">{toMoney(feature.price ?? 0)}</div>
        </SimpleBox>
      ))}
      <SimpleBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <p>Tax</p>
        <div className="text">{toMoney(0)}</div>
      </SimpleBox>
      <SimpleBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <p>Total to pay</p>
        <div className="text">{total}</div>
      </SimpleBox>
    </div>
  )
}
export default Breakdown
