import React from 'react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleCard } from '../../SimpleCard'
import { SimpleText } from '../../SimpleText'
import { Points } from '../../Plans/components/Single/components'
import { Checkbox, Label } from 'semantic-ui-react'
import Breakdown from '../Breakdown'
import { Coupon } from '../../../utils/coupon'
import { Plan, PlanSellingPoint } from '../../../type'
import { Discount } from '../../../fragments/modal/payment/DiscountNew'
import { ICartExtraFeatures } from '../../../hooks/useGetCheckoutConfig'
const ShieldRedIcon = require('~assets/images/icons/shield-red.svg')
const AlertIcon = require('~assets/images/icons/icon-alert.svg')
const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')
interface SummaryCardProps {
  total: string;
  coupon?: Coupon;
  activateNow: boolean;
  plan: Plan | undefined;
  userCount: number;
  handleToggleAddUser: () => void;
  firstPlanId: string;
  handleCouponFound: (coupon: Coupon) => void;
  handleRemoveCoupon: () => void;
  selectedFeatures: ICartExtraFeatures[];
  bienniallyFreeMonths: number;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  total,
  coupon,
  activateNow,
  plan,
  userCount,
  handleToggleAddUser,
  firstPlanId,
  handleCouponFound,
  handleRemoveCoupon,
  selectedFeatures,
  bienniallyFreeMonths
}) => {
  const points: PlanSellingPoint[] = [
    { icon: PlanCheckMarkIcon, title: '30 Day Free Trial' },
    { icon: PlanCheckMarkIcon, title: '24/7 Helpful Support' },
    { icon: PlanCheckMarkIcon, title: 'Unlimited Devices' },
    {
      icon: PlanCheckMarkIcon,
      title: 'VPN',
      subtitle: 'Internet Security',
    },
    { icon: PlanCheckMarkIcon, title: '1 User' },
  ]
  activateNow && points.shift()
  return (
    <SimpleCard className="checkout-summary-card">
      <SimpleBox
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap={12}
      >
        <img src={ShieldRedIcon} alt="shield" width={42} />
        <SimpleBox
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap={4}
        >
          <SimpleText size="title3">VirtualShield</SimpleText>
          <SimpleText size="title3" color="red">
            One
          </SimpleText>
        </SimpleBox>
      </SimpleBox>
      <Points items={points} secondary={true} footer={null} />
      <SimpleBox
        display="flex"
        flexDirection="column"
        paddingBottom={16}
        marginBottom={18}
        marginTop={20}
        borderBottom="1px solid #E8EAF0"
      >
        <SimpleText size="buttonText" color="grey">
          Extra features
        </SimpleText>
        <SimpleBox
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap={4}
          marginTop={8}
        >
          <Checkbox
            toggle
            className="add-one-checkbox"
            onChange={handleToggleAddUser}
          />
          <SimpleText size="text5">Add another user for $29/yr</SimpleText>
          <SimpleBox
            display="flex"
            flexDirection="column"
            position="relative"
            className="alert-box"
          >
            <Label pointing="below" className="alert-label">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry lorem ipsum.
            </Label>
            <img src={AlertIcon} alt="alert" width={16} />
          </SimpleBox>
        </SimpleBox>
      </SimpleBox>
      <Breakdown
        total={total}
        coupon={coupon}
        activateNow={activateNow}
        plan={plan}
        userCount={userCount}
        selectedFeatures={selectedFeatures}
        bienniallyFreeMonths={bienniallyFreeMonths}
      />
      <div className="discountForm">
        <Discount
          planId={firstPlanId}
          onCouponFound={handleCouponFound}
          onRemoveCoupon={handleRemoveCoupon}
        />
      </div>
    </SimpleCard>
  )
}

export default SummaryCard
