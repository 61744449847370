import { gql } from '@apollo/client'

export interface IExtraFeature {
  id: string;
  title: string;
  description: string;
  price: number;
  imageUrl: string;
  chargebeeId: string;
  position: number;
}

export const queryPlan = gql`
  query ($id: ID!) {
    plan(id: $id) {
      id
      name
      externalName
      description
      price
      period
      periodUnit
      pricingModel
      currencyCode
      trialPeriod
      trialPeriodUnit
      billingCycles
      freeQuantity
      itemFamily {
        name
      }
      item {
        metadata
      }
      __typename
    }
  }
`

export const queryCheckoutConfig = gql`
  query {
    useCheckoutConfiguration {
      checkoutSubscription {
        id
      }
      checkoutConfiguration {
        id
      }
    }
  }
`

export const queryCheckoutConfigDetail = gql`
  query checkoutConfiguration($id: ID!) {
    checkoutConfiguration(id: $id) {
      id
      title
      template
      freeMonths
      defaultCoupon
      payNowEnabled
      checkoutSteps {
        id
        stepType
        position
      }
      cartSteps {
        id
        stepType
        position
      }
      cartExtraFeatures {
        id
        title
        description
        price
        imageUrl
        chargebeeId
        position
      }
      cartPaymentMethods {
        id
        paymentMethod
        position
      }
    }
  }
`
export const queryCheckoutUpsellItems = gql`
  query checkoutConfiguration($id: ID!) {
    checkoutConfiguration(id: $id) {
      cartExtraFeatures {
        id
        title
        description
        price
        imageUrl
        chargebeeId
        position
      }
    }
  }
`

export const queryCheckoutUpsells = gql`
query useCheckoutConfigurationUpsell($checkoutSubscriptionId: ID!) {
  useCheckoutConfigurationUpsell(checkoutSubscriptionId: $checkoutSubscriptionId){
    subscriptionId
    availableUpsells
  }
}`

export const mutationSubscribeMutation = `
mutation($input: SubscribeInput!) {
  result: subscribe(input: $input) { token subscriptionId finalPrice }
}`

export const mutationPaypalMutation = `
mutation($id: ID!, $email: String!, $activateNow: Boolean, $coupon: String, $returnPath: String) {
  token: paypalToken(id: $id, email: $email, activateNow: $activateNow, coupon: $coupon, returnPath: $returnPath)
}`

export const mutationCreatePaymentMutation = `
mutation createPaymentIntent($input: CreatePaymentIntentInput!) {
  createPaymentIntent(input: $input) {
      clientSecret
  }
}`

export const createPaymentIntent = gql`
  mutation createPaymentIntent($input: CreatePaymentIntentInput!) {
    createPaymentIntent(input: $input) {
      clientSecret
    }
  }
`
export const attachAddon = gql`
  mutation($subscription: ID!, $id: ID!, $coupon: string) { 
    attachAddon(subscription: $subscription, id: $id, coupon: $coupon) 
  }
`
export const applyCoupon = gql`
    mutation ($code: String!, $priceId: ID) {
      coupon: redeemCoupon(code: $code, itemPriceId: $priceId) {
        id
        name
        discountType
        discountPercentage
        discountAmount
      }
    }
  `
